import React from "react"
import { Helmet } from "react-helmet"
import { ParallaxProvider } from "react-scroll-parallax"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Cookies from "../components/Cookies/Cookies"

const Info = props => {
  return (
    <>
      <Helmet>
        <html lang="cs" />
        <meta charSet="utf-8" />
        <title>Magnus Art</title>
        <meta
          name="description"
          content="J&amp;T Banka vybudovala vlastní sbírku současného umění složenou z děl laureátů Ceny Jindřicha Chalupeckého."
        />
      </Helmet>
      <ParallaxProvider>
        <Header {...props} />
        <Cookies />
        <main className="info">
          <p className="text-left mb-3 light">
            J&amp;T Banka vybudovala vlastní sbírku současného umění složenou z
            děl laureátů Ceny Jindřicha Chalupeckého od roku 1990 až do dnes.
            Obsahuje více než třicet děl nebo sérií, na kterých představuje
            vývoj mladého porevolučního umění. Sbírka je aktuálně k vidění v
            Galerii Magnus Art v pražském Karlíně, Sokolovská 700/113a.
          </p>

          <div className="picture-container center">
            <p className="text-right">Fotografie &copy; BoysPlayNice</p>
            <Img
              className="picture-container__image"
              fluid={props.data.file.childImageSharp.fluid}
              alt="1990 Vladimir Kokolia"
            />
          </div>
        </main>
        <div className="info-footer">
          <div className="info-footer__contact">
            <p className="mb-1 medium">
              <strong>Kontakty</strong>
            </p>
            <ul className="mb-1 medium">
              <li>Galerie Magnus Art</li>
              <li>Sokolovská 700/113a</li>
              <li>18000 Praha 8 - Karlín</li>
              <li>
                <a href="mailto:info@galeriemagnusart.cz">
                  info@galeriemagnusart.cz
                </a>
              </li>
              <li>
                <a href="https://www.galeriemagnusart.cz/">
                  www.galeriemagnusart.cz
                </a>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/YJdBqcBuLB3KCrac8"
                  target="_blank"
                  rel="noreferrer"
                >
                  (mapa)
                </a>
              </li>
            </ul>
            <br />
            <p>
              V rámci České republiky je sbírka J&amp;T Banky Magnus Art první
              jasně definovanou a strukturovanou institucionální snahou vytvořit
              komplexní kolekci, která by dokumentovala vývoj současného českého
              umění od devadesátých let dvacátého století až do současnosti.
            </p>
            <br />
            <p>
              Vodítkem pro výběr nových akvizic sbírky Magnus Art je Cena
              Jindřicha Chalupeckého. Toto nejvýznamnější ocenění v České
              republice pro umělce do 35 let je udělováno už od roku 1990, kdy
              na popud Václava Havla, Theodora Pištěka a Jiřího Koláře vzniklo.
              J&T Banka je dlouhodobým partnerem Ceny Jindřicha Chalupeckého a
              má díky tomu ve své sbírce zastoupeno na třicet laureátů této
              prestižní ceny z celé doby její existence.
            </p>
            <br />
            <p>
              Jsme přesvědčeni, že sbírka se postupně stává stále důležitějším
              svědectvím o době, společnosti a českém výtvarném umění posledních
              dekád. Její hodnota je tedy obsažena nejen v jednotlivých dílech,
              ale především v ucelenosti sbírky, která je významným,
              reprezentativním a také živým souborem děl.
            </p>
            <br />
          </div>

          <div className="info-footer__social">
            <p className="medium">Sledujte nás na sociálních sítích</p>
            <ul className="info-footer__social-list medium">
              <li>
                <a
                  href="https://www.facebook.com/jtbankaart/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/jtbanka_art/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Footer />
      </ParallaxProvider>
    </>
  )
}

export default Info

export const query = graphql`
  {
    file(relativePath: { eq: "info/info.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1865, maxHeight: 1203, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
